import React, { useEffect, useState } from "react";
import { Question, Teams } from "../Components";
import { gameCollection } from "../firebase.config";

import playBtn from "../assets/images/play_btn.png";
import CircularProgress from "@mui/material/CircularProgress";
import correctBtn from "../assets/images/answer-correct.png";
import wrongBtn from "../assets/images/answer-wrong.png";
import nextBtn from "../assets/images/next-q.png";
import prevBtn from "../assets/images/prev-q.png";
import team1 from "../assets/images/team1.png";
import team2 from "../assets/images/team2.png";
import team3 from "../assets/images/team3.png";
import team4 from "../assets/images/team4.png";
import team5 from "../assets/images/team5.png";

import { Typography } from "@mui/material";

const teamImages = {
  team1,
  team2,
  team3,
  team4,
  team5,
};

const ModeratorScreen = () => {
  const [loaded, setLoaded] = useState(false);
  const [questions, setQuestions] = useState();
  const [game, setGame] = useState({});
  const [teams, setTeams] = useState({});

  const showWinner = () => {
    let score = 0;
    let teamId = "";
    Object.keys(teams).forEach((tId) => {
      if (teams[tId].score > score) {
        score = teams[tId].score;
        teamId = tId;
      }
    });
    gameCollection.doc("game").set({
      ...game,
      winner: teamId,
      show_winner: true,
    });
  };

  const restartGame = async () => {
    setLoaded(false);
    await gameCollection.doc("game").set({
      active: false,
      buzz: false,
      counter: 0,
      question: questions ? { ...questions.questions[1], id: 1 } : {},
    });
    await gameCollection.doc("teams").set({
      team1: {
        name: "Edison Team",
        id: "team1",
        active: false,
        playing: false,
        wrong: false,
        score: 0,
      },
      team2: {
        id: "team2",
        name: "Einstein Team",
        playing: false,
        wrong: false,
        active: false,
        score: 0,
      },
      team3: {
        id: "team3",
        name: "Hawking Team",
        score: 0,
        wrong: false,
        active: false,
        playing: false,
      },
      team4: {
        id: "team4",
        name: "Tesla Team",
        active: false,
        playing: false,
        wrong: false,
        score: 0,
      },
      team5: {
        id: "team5",
        name: "Newton Team",
        active: false,
        playing: false,
        wrong: false,
        score: 0,
      },
    });
  };

  const startGame = async () => {
    setLoaded(false);
    await gameCollection
      .doc("game")
      .set({ ...game, active: true, buzz: true, buzzTimestamp: Date.now() });
    setLoaded(true);
  };

  useEffect(() => {
    const init = async () => {
      gameCollection.doc("questions").onSnapshot((docSnap) => {
        if (docSnap.exists) {
          setQuestions(docSnap.data());
        }
      });
      gameCollection.doc("game").onSnapshot((docSnap) => {
        setLoaded(true);
        if (docSnap.exists) {
          setGame(docSnap.data());
        }
      });
      gameCollection.doc("teams").onSnapshot((docSnap) => {
        setLoaded(true);
        if (docSnap.exists) {
          setTeams(docSnap.data());
        }
      });
    };
    init();
  }, []);

  const correctAnswer = () => {
    if (game.question && game.question.show_answer) return;
    gameCollection.doc("game").set({
      ...game,
      counter: 0,
      question: {
        ...game.question,
        correct: true,
      },
    });
    const activeTeam = Object.keys(teams).filter(
      (teamId) => teams[teamId].active
    );
    if (activeTeam.length > 0) {
      let score = teams[activeTeam].score;
      let buzzTime = teams[activeTeam].buzzTime;
      if (score) {
        score += 1;
      } else {
        score = 1;
      }
      if (buzzTime === undefined) {
        buzzTime = 0;
      }
      buzzTime += teams[activeTeam].buzzTimestamp - game.buzzTimestamp;
      console.log("buzzTime", buzzTime);
      teams[activeTeam].score = score;
      teams[activeTeam].buzzTime = buzzTime;
    }
    Object.keys(teams).map((teamId) => {
      teams[teamId] = {
        ...teams[teamId],
        active: false,
        wrong: false,
        buzzTimestamp: 0,
      };
      return teamId;
    });
    gameCollection.doc("teams").set(teams);
  };

  const wrongAnswer = () => {
    if (game.question && game.question.show_answer) return;
    const activeTeam = Object.keys(teams).filter(
      (teamId) => teams[teamId].active
    );
    teams[activeTeam].active = false;
    teams[activeTeam].wrong = true;
    teams[activeTeam].buzzTimestamp = Date.now();
    gameCollection.doc("teams").set(teams);
    gameCollection.doc("game").set({ ...game, counter: 0 });
  };

  const nextQuestion = () => {
    let qIndex = 1;
    if (game.question) {
      if (parseInt(game.question.id) >= Object.keys(questions.questions).length)
        return;
      qIndex = parseInt(game.question.id) + 1;
    }
    if (
      questions.questions &&
      Object.keys(questions.questions).length >= qIndex
    ) {
      let nextQuestion = questions.questions[qIndex];
      nextQuestion.id = qIndex;
      gameCollection.doc("game").set({
        ...game,
        counter: 0,
        question: nextQuestion,
        buzz: true,
        buzzTimestamp: Date.now(),
      });
    }
    Object.keys(teams).map((teamId) => {
      teams[teamId] = {
        ...teams[teamId],
        active: false,
        wrong: false,
        buzzTimestamp: 0,
      };
      return teamId;
    });
    gameCollection.doc("teams").set(teams);
  };

  const prevQuestion = () => {
    let qIndex = 1;
    if (game.question) {
      if (parseInt(game.question.id) <= 1) return;
      qIndex = parseInt(game.question.id) - 1;
    }
    if (
      questions.questions &&
      Object.keys(questions.questions).length >= qIndex
    ) {
      let nextQuestion = questions.questions[qIndex];
      nextQuestion.id = qIndex;
      gameCollection.doc("game").set({
        ...game,
        counter: 0,
        question: nextQuestion,
        buzz: false,
      });
    }
    Object.keys(teams).map((teamId) => {
      teams[teamId] = {
        ...teams[teamId],
        active: false,
        wrong: false,
        buzzTimestamp: 0,
      };
      return teamId;
    });
  };

  if (!loaded) {
    return (
      <div className="Full">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  if (!game.active) {
    return (
      <div className="Full">
        <button className="btn play-btn" onClick={startGame}>
          <img src={playBtn} alt="play" />
        </button>
        <div className="Teams-row">
          {Object.keys(teams).map((t, index) => (
            <div
              key={index}
              className="Team-circle"
              style={{ opacity: teams[t].playing ? 1 : 0.5 }}
            >
              <img src={teamImages[t]} alt="profile" />
              <Typography color="#fff">{teams[t].name}</Typography>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div>
      <button onClick={restartGame} className="btn reset-btn">
        Restart Game
      </button>
      <button onClick={showWinner} className="btn show-winner-btn">
        Show Winner
      </button>
      <Question question={game.question} />
      <Teams />
      <div className="App-answer-btns">
        <button className="btn" onClick={correctAnswer}>
          <img src={correctBtn} alt="correct" />
        </button>
        <button className="btn" onClick={wrongAnswer}>
          <img src={wrongBtn} alt="wrong" />
        </button>
      </div>
      <div className="Q-nav-btns">
        <button className="btn" onClick={prevQuestion}>
          <img src={prevBtn} alt="prev" />
        </button>
        <button className="btn" onClick={nextQuestion}>
          <img src={nextBtn} alt="next" />
        </button>
      </div>
    </div>
  );
};

export default ModeratorScreen;
