import "./App.css";
import { Routes, Route } from "react-router-dom";
import { GameScreen, ModeratorScreen, TeamScreen } from "./Screens";

import bg from "./assets/images/bg.png";
import logo from "./assets/images/logo.png";

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${bg})` }}>
      <img className="App-logo" src={logo} alt="logo" />
      <Routes>
        <Route path="/" element={<GameScreen />} />
        <Route path="/team" element={<TeamScreen />} />
        <Route path="/moderator" element={<ModeratorScreen />} />
      </Routes>
    </div>
  );
}

export default App;
