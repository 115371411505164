import { useEffect, useState } from "react";
import { gameCollection } from "../firebase.config";
import { Team } from ".";

const Teams = () => {
  const [teams, setTeams] = useState({
    team1: {},
    team2: {},
    team3: {},
    team4: {},
    team5: {},
  });

  useEffect(() => {
    const init = async () => {
      gameCollection.doc("teams").onSnapshot((docSnap) => {
        if (docSnap.exists) {
          setTeams(docSnap.data());
        }
      });
    };
    init();
  }, []);

  return (
    <div className="App-teams">
      <Team team={teams.team1} />
      <Team team={teams.team2} />
      <Team team={teams.team3} />
      <Team team={teams.team4} />
      <Team team={teams.team5} />
    </div>
  );
};

export default Teams;
