import React, { useEffect, useState } from "react";
import { Question, Teams } from "../Components";
import { gameCollection } from "../firebase.config";

import counterBg from "../assets/images/counter-bg.png";
import introVideo from "../assets/images/intro.mp4";
import CircularProgress from "@mui/material/CircularProgress";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import team1Winner from "../assets/images/team1-winner.jpg";
import team2Winner from "../assets/images/team2-winner.jpg";
import team3Winner from "../assets/images/team3-winner.jpg";
import team4Winner from "../assets/images/team4-winner.jpg";
import team5Winner from "../assets/images/team5-winner.jpg";

import correctSound from "../assets/sounds/correct.mp3";
import winnerSound from "../assets/sounds/winner.mp3";

import useSound from "use-sound";

const winnerImages = {
  team1: team1Winner,
  team2: team2Winner,
  team3: team3Winner,
  team4: team4Winner,
  team5: team5Winner,
};

const GameScreen = () => {
  const [playCorrect] = useSound(correctSound);
  const [playWinner] = useSound(winnerSound);

  const [showImage, setShowImage] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [game, setGame] = useState({ counter: 0 });

  const resetCounter = async () => {
    gameCollection.doc("game").set({ ...game, counter: 0 });
  };

  useEffect(() => {
    const init = async () => {
      gameCollection.doc("game").onSnapshot((docSnap) => {
        setLoaded(true);
        if (docSnap.exists) {
          if (
            docSnap.data().question &&
            docSnap.data().question.image !== undefined
          ) {
            setShowImage(true);
            setTimeout(() => setShowImage(false), 10000);
          }
          if (game.active && !docSnap.data().active) {
            window.location.reload();
          }
          setGame(docSnap.data());
          if (docSnap.data().question && docSnap.data().question.correct) {
            playCorrect();
            setTimeout(
              () =>
                gameCollection.doc("game").set({
                  ...docSnap.data(),
                  question: {
                    ...docSnap.data().question,
                    correct: false,
                    show_answer: true,
                    buzz: false,
                  },
                }),
              2000
            );
          }
        }
      });
    };
    init();
  }, []);

  if (!loaded) {
    return (
      <div className="Full">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  if (!game.active) {
    return (
      <div className="Game-intro">
        <video loop autoPlay muted>
          <source src={introVideo} />
        </video>
      </div>
    );
  }

  if (game.show_winner && game.winner) {
    playWinner();
    return (
      <div className="Game-winner">
        <img src={winnerImages[game.winner]} alt="winner" />
      </div>
    );
  }

  return (
    <div>
      {game.question && <Question question={game.question} />}
      {game.question && game.question.show_answer && (
        <div
          className="Q-answer"
          dangerouslySetInnerHTML={{ __html: game.question.answer }}
        />
      )}
      {((game.question &&
        game.question.image !== undefined &&
        game.question.show_answer) ||
        showImage) && (
        <img
          src={game.question.image}
          alt="vision"
          style={{
            position: "fixed",
            bottom: "5vh",
            left: "10vw",
            width: "80vw",
            height: "50vh",
            objectFit: "contain",
            zIndex: 999,
          }}
        />
      )}
      {game.question && !game.question.show_answer && <Teams />}
      <div
        className="Q-counter"
        style={{ backgroundImage: `url(${counterBg})`, opacity: game.counter }}
      >
        {loaded && game.counter > 0 && (
          <CountdownCircleTimer
            isPlaying={game.counter > 0}
            duration={game.counter}
            colors={[["#1482ff", 1]]}
            onComplete={resetCounter}
          >
            {({ remainingTime, animatedColor }) => <h1>{remainingTime}</h1>}
          </CountdownCircleTimer>
        )}
      </div>
    </div>
  );
};

export default GameScreen;
