import { useEffect, useState } from "react";
import { gameCollection } from "../firebase.config";

import team1 from "../assets/images/team1.png";
import team2 from "../assets/images/team2.png";
import team3 from "../assets/images/team3.png";
import team4 from "../assets/images/team4.png";
import team5 from "../assets/images/team5.png";
import activeBuzz from "../assets/images/buzz-active.png";
import disabledBuzz from "../assets/images/buzz-disabled.png";

import { Typography } from "@mui/material";

const teamImages = {
  team1,
  team2,
  team3,
  team4,
  team5,
};

const TeamScreen = () => {
  const [loading, setLoading] = useState(false);
  const [game, setGame] = useState({});
  const [team, setTeam] = useState();
  const [teams, setTeams] = useState({
    team1: {},
    team2: {},
    team3: {},
    team4: {},
    team5: {},
  });

  const selectTeam = async (teamId) => {
    if (loading) return;
    teams[teamId].playing = true;
    await gameCollection.doc("teams").set(teams);
    setTeam(teamId);
    localStorage.setItem("teamId", teamId);
  };

  const buzzClicked = async () => {
    if (
      teams[team].wrong ||
      !game.active ||
      !game.buzz ||
      game.counter !== 0 ||
      !game.question ||
      game.question.show_answer
    )
      return null;
    teams[team].active = true;
    teams[team].buzzTimestamp = Date.now();
    await gameCollection.doc("teams").set(teams);
    await gameCollection.doc("game").set({ ...game, counter: 25 });
    setTeam(team);
  };

  useEffect(() => {
    const init = async () => {
      const selectedTeam = localStorage.getItem("teamId");
      gameCollection.doc("game").onSnapshot((docSnap) => {
        if (docSnap.exists) {
          setGame(docSnap.data());
        }
      });
      gameCollection.doc("teams").onSnapshot((docSnap) => {
        if (docSnap.exists) {
          setTeams(docSnap.data());
          if (docSnap.data()[selectedTeam].playing) {
            setTeam(selectedTeam);
          } else {
            setTeam();
          }
        }
      });
    };
    init();
  }, []);

  return (
    <div className="TeamPage">
      {team && (
        <div className="buzz-container">
          <button className="btn buzz" onClick={() => buzzClicked()}>
            <img
              src={
                !teams[team].wrong &&
                game.active &&
                game.buzz &&
                game.counter === 0 &&
                game.question &&
                !game.question.show_answer
                  ? activeBuzz
                  : disabledBuzz
              }
              alt="buzz"
            />
          </button>
          <img className="team-image" src={teamImages[team]} alt="profile" />
        </div>
      )}
      {!team &&
        Object.keys(teams).map((t, index) => (
          <button
            key={index}
            className="btn"
            disabled={teams[t].playing}
            onClick={() => selectTeam(t)}
          >
            <img src={teamImages[t]} alt="profile" />
            <Typography color="#fff">{teams[t].name}</Typography>
          </button>
        ))}
    </div>
  );
};

export default TeamScreen;
