import activeDot from "../assets/images/active.png";
import wrongDot from "../assets/images/wrong.png";

import team1 from "../assets/images/team1.png";
import team2 from "../assets/images/team2.png";
import team3 from "../assets/images/team3.png";
import team4 from "../assets/images/team4.png";
import team5 from "../assets/images/team5.png";
import buzzSound from "../assets/sounds/buzz.mp3";

import useSound from "use-sound";
import { useEffect } from "react";
import { gameCollection } from "../firebase.config";

const teamImages = {
  team1,
  team2,
  team3,
  team4,
  team5,
};

const Team = ({ team }) => {
  const [playBuzz] = useSound(buzzSound);

  useEffect(() => {
    const init = async () => {
      gameCollection.doc("game").onSnapshot((docSnap) => {
        if (docSnap.exists) {
          if (team.active && !docSnap.data().show_answer) playBuzz();
        }
      });
    };
    init();
  }, []);

  if (!team.id) return null;
  return (
    <div className="App-team">
      <img src={teamImages[team.id]} alt="profile" />
      <span style={{ textDecoration: team.wrong ? "line-through" : "none" }}>
        {team.name}
      </span>
      <div>
        {team.active && <img src={activeDot} alt="dot" />}
        {team.wrong && <img src={wrongDot} alt="dot" />}
      </div>
    </div>
  );
};

export default Team;
