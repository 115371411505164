import questionBg from "../assets/images/question_bg.png";

const Question = ({ question }) => {
  return (
    <div
      className="App-question"
      style={{ backgroundImage: `url(${questionBg})` }}
    >
      {question.text}
    </div>
  );
};

export default Question;
